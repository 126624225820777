<template>
  <div class="main-view">
    <div class="main-view-bg">
      <el-form
        class="staff-from"
        v-loading="loading"
        element-loading-text="加载中…"
        ref='elFormDom'
        label-width='110px'
        label-position='top'
        :model='form.data'
        :rules='form.rules'
        size="small"
        hide-required-asterisk
      >
        
        <el-row class="licence_wrap">
          <div class="flex space-between">
            <div class="hint">此处为自动识别营业执照信息，如营业执照信息有修改，需重新上传营业执照图片，此类信息需提交审核通过后才能生效。</div>
            <div class="btnWarning" v-if="company_status == 0">营业执照信息审核中</div>
          </div>
          <el-col :span="6" style="width:200px;">
            <div class="label">营业执照</div>
            <el-image
              style="width: 160px; height: 160px;margin:10px 0; border-radius:6px;"
              :src="form.data.business_license"
              fit="cover"
              :preview-src-list="[form.data.business_license]"></el-image>
            <el-upload
              v-if="company_status != 0"
              class="upload-demo"
              action=""
              :show-file-list="false"
              :before-upload="beforeAvatarUpload"
              :on-remove="handleRemove"
              :http-request="httpRequest">
              <div class="imgbtn">重新上传营业执照</div>
            </el-upload>
          </el-col>
          <el-col :span="14">
            <el-row :gutter="30">
              <el-col :span="24">
                <el-row :gutter="30">
                  <el-col :span="12">
                    <el-form-item label="" prop="name">
                      <span slot="label" class="label">机构名称</span>
                      <el-input
                        v-model="form.data.name"
                        placeholder="自动获取营业执照信息"
                        :disabled="true">
                      </el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-col>
              <el-col :span="12">
                <el-form-item label="" prop="legal_person">
                  <span slot="label" class="label">法人姓名</span>
                  <el-input
                    v-model="form.data.legal_person"
                    placeholder="自动获取营业执照信息"
                    :disabled="true">
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="" prop="organization_code">
                  <span slot="label" class="label">组织机构代码</span>
                  <el-input
                    v-model="form.data.organization_code"
                    placeholder="自动获取营业执照信息"
                    :disabled="true">
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="" prop="area_arr">
                  <span slot="label" class="label">注册地址</span>
                  <el-row :gutter="10">
                    <el-col :span="12">
                      <el-cascader
                        style="width:100%;"
                        :popper-append-to-body="false"
                        popper-class="areaCascaderWrap"
                        v-model="form.data.area_arr"
                        :options="areaArr"
                        :props="{ checkStrictly: false }"
                        :disabled="company_status == 0 ? true : false"
                        >
                      </el-cascader>
                    </el-col>
                    <el-col :span="12">
                      <el-input
                        v-model="form.data.address"
                        placeholder="自动获取营业执照信息"
                        :disabled="true">
                      </el-input>
                    </el-col>
                  </el-row>
                </el-form-item>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
        <el-row :gutter="50">
          <el-col :span="7" >
            <el-form-item label="机构简称" prop="simple_name">
              <el-input
                v-model="form.data.simple_name"
                placeholder="请输入机构简称，用于展示在产品详情中"
                clearable>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="行业类型" prop="industry">
              <el-input
                v-model="form.data.industry"
                placeholder="请输入所属的行业类型"
                clearable>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="机构规模（人）" prop="max_contain_count">
              <el-input
                v-model="form.data.max_contain_count"
                placeholder="请输入最大容纳人数"
                @input="form.data.max_contain_count=form.data.max_contain_count.replace(/[^\d^\.]/g,'')"
                clearable
                >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="联系人" prop="contact">
              <el-input
                v-model="form.data.contact"
                placeholder="请输入联系人"
                clearable>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="联系电话" prop="contact_mobile">
              <el-input
                v-model="form.data.contact_mobile"
                maxlength="11"
                placeholder="请输入联系人手机号码"
                clearable>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="14">
            <el-form-item label="机构简介" prop="description">
              <el-input
                v-model="form.data.description"
                type="textarea"
                :autosize="{ minRows: 4, maxRows: 8}"
                placeholder="请输入机构简介"
                clearable
                maxlength="500"
                show-word-limit>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="场地照片" prop="space_url" class="imageWrap">
              <template slot="label">场地照片</template>
              <span class="label-hint">（至少上传1张，限9张）</span>
              <el-upload
                action=""
                :limit="9"
                list-type="picture-card"
                :on-preview="handlePictureCardPreview"
                :on-remove="handleRemove"
                :file-list="fileList"
                :http-request="httpRequestBack"
                accept="image/jpeg, image/png, image/gif">
                <img style="104px;height:104px;object-fit: cover;" :src="require('@/assets/img/upload.png')" />
              </el-upload>
              <el-dialog :visible.sync="dialogVisible">
                <img width="100%" :src="dialogImageUrl" alt="">
              </el-dialog>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <!-- company_status 0:审核中  1审核通过  2审核不通过 -->
    <div class="licence_foot">
      <el-button type="primary" @click="confirm">提交</el-button>
    </div>
  </div>
</template>

<script>
import { REG_EXP } from '@/util'
export default {
  name: 'OrganIndex',
  data() {
    return {
      loading: false,
      status: 1,
      form: {
        data: {
          business_license: '', //营业执照
          name: '',//机构名称
          legal_person: '',//法人姓名
          organization_code: '',//组织机构代码
          area_arr: [],
          province: 0, //注册地址省
          city: 0, //注册地址市
          area: 0, //注册地址区
          address: "", //地址
          simple_name: "", //机构简称
          industry: "", //行业类型
          max_contain_count: "", //最大容纳人数
          contact: "", //联系人
          contact_mobile: "", //电话
          description: "", //机构简介
          space_url: "" //场地照片，用,分割
        },
        rules: {
          business_license: [{ required: true, message:'必填项', trigger: 'change' }],
          name: [{ required: true, message:'必填项', trigger: 'change' }],
          legal_person: [{ required: true, message:'必填项', trigger: 'change' }],
          organization_code: [{ required: true, message:'必填项', trigger: 'change' }],
          area_arr: [{ required: true, message:'必填项', trigger: 'change' }],
          address: [{ required: true, message:'必填项', trigger: 'change' }],
          simple_name: [{ required: true, message:'必填项', trigger: 'change' }],
          industry: [{ required: true, message:'必填项', trigger: 'change' }],
          max_contain_count: [{ required: true, message:'必填项', trigger: 'change' }],
          contact: [{ required: true, message:'必填项', trigger: 'change' }],
          contact_mobile: [
            { required: true, message:'必填项', trigger: 'change' },
            { pattern: REG_EXP.mobilePhone, message: "手机号不正确", trigger: 'blur' }
          ],
          description: [{ required: true, message:'必填项', trigger: 'blur' }],
          space_url: [{ required: true, message:'必填项', trigger: 'change' }],
        },
      },
      fileList: [],
      dialogVisible: false,
      dialogImageUrl: [],
      areaArr: [],
      company_id: 0,//机构ID 0表示未认证机构
      company_status: 0,//状态：0:审核中,1审核通过,2审核不通过,(审核中不能修改营业执照信息)
    }
  },
  created() {
    this.getIndex();
    this.getArea();
  },
   methods: {
    // 获取机构认证状态
    getIndex() {
      this.$http.get('/company/index/index', {params:{}}).then(res => {
        if(res.code == 1) {
          this.company_id = res.data.company_id; //机构ID 0表示未认证机构
          this.company_status = res.data.status; //状态：0:审核中  1审核通过  2审核不通过
          this.getInfo();
        }
      })
    },
    // 获取机构信息
    getInfo() {
      this.$http.get('/company/index/info', {params:{}}).then(res => {
        this.form.data = res.data;
        this.form.data.area_arr = [res.data.province,res.data.city,res.data.area];
        var _space_url = this.form.data.space_url
        console.log(_space_url)
        if(_space_url.length > 0) {
          this.fileList = []
          _space_url.forEach(v => {
            let str = {
              name: '',
              url: v
            }
            this.fileList.push(str)
          })
        }
      })
    },
    // 获取省市区
    getArea() {
      this.$http.post('/common/district/all',{}).then(res => {
        if(res.code == 1) {
          this.areaArr = res.data.list;
        }
      })
    },
    beforeAvatarUpload(file) {
      const isLt5M = file.size / 1024 / 1024 < 5;
      if (!isLt5M) {
        this.$message.error('上传文件大小不能超过 5MB!');
      }
      return isLt5M;
    },
    async httpRequest(file) {
      let formData = new FormData();
      formData.append('file', file.file);
      this.loading = true;
      await this.$http.post('/common/qiniu/upload', {formData, type:'upload'}).then(res => {
        this.form.data.business_license = res.data.all_path_url;
        this.$refs.elFormDom.clearValidate();
      }).finally(() => {
        this.loading = false;
      })
      // 识别营业执照
      await this.$http.post('/common/baidu/license', {url: this.form.data.business_license}).then(res => {
        console.log(res.data)
        this.form.data.name = res.data.name;
        this.form.data.legal_person = res.data.legal;
        this.form.data.organization_code = res.data.credit_code;
        this.form.data.address = res.data.address;
      }).finally(() => {
        this.loading = false;
      })
    },
    // 上传
    httpRequestBack(file) {
      let formData = new FormData();
      formData.append('file', file.file);
      this.$http.post('/common/qiniu/uploadFile', {formData, type:'upload'}).then(res => {
        if(res.code === 1) {
          let str = {
            url: res.data.all_path_url,
            name: file.file.name,
            uid: file.file.uid
          }
          this.fileList.push(str);
          // this.form.data.space_url = res.data.all_path_url;
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleRemove(file) {
      this.fileList.forEach((v,i) => {
        if(file.uid == v.uid) {
          this.fileList.splice(i, 1)
        }
      })
    },
    confirm() {
      this.$refs.elFormDom.validate(valid => {
        if(valid) {
          let _params = {...this.form.data}
          if(this.fileList.length > 0){
            let _image = []
            this.fileList.forEach(v => {
              _image.push(v.url)
            })
            _params.space_url = _image.join(",")
          } else {
            _params.space_url = '';
          }
          _params.province = this.form.data.area_arr[0];
          _params.city = this.form.data.area_arr[1];
          _params.area = this.form.data.area_arr[2];
          delete _params.area_arr;
          this.$http.post('/company/index/cert', _params).then(res => {
            if(res.code == 1) {
              this.$message.success('提交成功！')
              this.getIndex();
            }
          })
        }
      })
    },
  }
}
</script>
<style scoped>
.main-view {
  margin: 0 !important;
}
.main-view-bg {
  background: #fff;
  margin: 20px;
  border-radius: 6px;
  padding: 20px 32px;
  overflow: auto;
}
.licence_wrap {
  background: #FCFCFC;
  border: solid 1px #E5E5E5;
  padding: 20px;
  border-radius: 3px;
  margin-bottom: 10px;
}
.licence_foot {
  background: #fff;
  padding: 10px 20px;
  margin-top: auto;
  text-align: right;
}
.flex {
  display: flex;
}
.space-between {
  width: 100%;
  justify-content: space-between;
  margin-bottom: 15px;
}
.hint {
  font-size: 14px;
  color: #F35149;
}
.imgbtn {
  width: 160px;
  border: solid 1px #007DF2;
  color: #007DF2;
  border-radius: 2px;
  height: 36px;
  line-height: 36px;
}
.staff-from {
  overflow-y: scroll;
  overflow-x: hidden;
  height: 100%;
}

.imageWrap ::v-deep .el-upload--picture-card {
  border: none;
  width: 104px;
  height: 104px;
}
.imageWrap ::v-deep .el-upload-list--picture-card .el-upload-list__item {
  width: 104px;
  height: 104px;
}
.imageWrap ::v-deep .el-range-editor--small .el-range-separator {
  width: 20px;
}

.staff-from::-webkit-scrollbar {
  display: none;
}
.label {
  color: #999999;
  font-size: 14px;
}
.label-hint {
  font-size: 14px;
  color: rgb(153, 153, 153);
  position: absolute;
  top: -37px;
  left: 62px;
}
.btnPrimary {
  background: #7EBCF7;
  color: #fff;
  text-align: center;
  width: 160px;
  height: 38px;
  line-height: 38px;
  border-radius: 3px;
}
.btnWarning {
  background: #FFF3EE;
  color: #FF5D04;
  text-align: center;
  width: 180px;
  height: 38px;
  line-height: 38px;
  border-radius: 3px;
}
</style>